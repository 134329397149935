import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HANDLED_ERRORS } from '../../../shared/configs/http-context.config';
import { RequestMetadata } from '../../../shared/models/request-metadata.dto';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private metadata: RequestMetadata = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
    context: new HttpContext().set(HANDLED_ERRORS, []),
  };
  protected readonly apiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  protected convertToFilterParams(params: Record<string, any>): Record<string, any> {
    return Object.entries(params).reduce((filteredParamsObj, [paramKey, paramValue]) => {
      filteredParamsObj[`filter[${paramKey}]`] = paramValue;

      return filteredParamsObj;
    }, {} as Record<string, any>);
  }

  protected delete<T>(url: string = '', metadata: RequestMetadata = {}): Observable<T> {
    return this.httpClient
      .delete<T>(url, {
        ...this.metadata,
        ...metadata,
      })
      .pipe(map((res) => res));
  }

  protected get<T>(url: string = '', metadata: RequestMetadata = {}): Observable<T> {
    return this.httpClient
      .get<T>(url, {
        ...this.metadata,
        ...metadata,
      })
      .pipe(map((res) => res));
  }

  protected getQueryStringParameters(params: Record<string, any>): HttpParams {
    // Filter out null/undefined parameters
    const filteredParams = Object.entries(params).reduce(
      (filteredParamsObj, [paramKey, paramValue]) => {
        if (paramValue != null) {
          filteredParamsObj[paramKey] = paramValue;
        }

        return filteredParamsObj;
      },
      {} as Record<string, any>,
    );

    // Return encoded http params
    return new HttpParams({
      fromObject: filteredParams,
    });
  }

  protected post<T>(
    url: string = '',
    body: object | null = null,
    metadata: RequestMetadata = {},
  ): Observable<T> {
    return this.httpClient
      .post<T>(url, body, {
        ...this.metadata,
        ...metadata,
      })
      .pipe(map((res) => res));
  }

  protected put<T>(
    url: string = '',
    body: object | null = null,
    metadata: RequestMetadata = {},
  ): Observable<T> {
    return this.httpClient
      .put<T>(url, body, {
        ...this.metadata,
        ...metadata,
      })
      .pipe(map((res) => res));
  }
}
