import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { environment } from '../../../../environments/environment';
import { FormErrors } from '../../../shared/configs/form-errors.config';
import { CustomError } from '../../../shared/models/api-response';
import { HelperService } from '../../services/helper/helper.service';
import { AuthService } from '../../services/http/auth/auth.service';
import { ValidationService } from '../../services/validation/validation.service';

import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  forgotPasswordForm: FormGroup;
  isFirstPage: boolean = true;
  isLoading: boolean = false;
  isPasswordChanged: boolean = false;
  isResetPassword: boolean = false;
  isVerifyOtp: boolean = false;
  resendOtpTimeLeft: number = 0;
  resetPasswordForm: FormGroup;

  private destroy$: Subject<void> = new Subject<void>();
  private resendOtpInterval: any;
  private selectedUserID?: string;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private validationService: ValidationService,
  ) {
    this.forgotPasswordForm = this.createForgotPasswordFormGroup();
    this.resetPasswordForm = this.createResetPasswordFormGroup();
  }

  ngOnInit(): void {
    this.setEventListeners();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickResendOtp(): void {
    this.startTimer();
    this.resendOtp();
  }

  onClickSavePassword(): void {
    const formData = {
      ...this.resetPasswordForm.value,
      device: 'browser',
      id: this.selectedUserID,
      otp: this.forgotPasswordForm.get(['otp'])?.value,
      username: this.forgotPasswordForm.get('username')?.value,
    };

    this.authService
      .resetPassword(formData, [422])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          // Success
          this.helperService.showMessage('success', res.message);
          this.isResetPassword = false;
          this.isPasswordChanged = true;
        },
        error: (err: CustomError) => {
          // Error
          this.validationService.addResponseErrorToForm(this.resetPasswordForm, err);
        },
      });
  }

  onClickSendOTP(): void {
    const username = this.forgotPasswordForm.get('username')?.value;
    const formData = {
      username,
    };

    this.isLoading = true;
    this.authService
      .fetchOtp(formData, [422])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          // Success
          this.selectedUserID = res.data.id;
          this.helperService.showMessage('success', res.message);
          this.isLoading = false;
          this.isFirstPage = false;
          this.isVerifyOtp = true;
        },
        error: (err: CustomError) => {
          // Error
          this.helperService.showMessage('error', err.errors.message[0]);
          this.validationService.addResponseErrorToForm(this.forgotPasswordForm, err);
        },
      });
  }

  onClickVerifyOTP(): void {
    const formData = {
      ...this.forgotPasswordForm.value,
      id: this.selectedUserID,
    };

    this.authService
      .verifyOtp(formData, [422])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          // Success
          this.helperService.showMessage('success', res.message);
          this.isVerifyOtp = false;
          this.isResetPassword = true;
        },
        error: (err: CustomError) => {
          // Error
          this.helperService.showMessage('error', err.errors.message[0]);
          this.validationService.addResponseErrorToForm(this.forgotPasswordForm, err);
        },
      });
  }

  private createForgotPasswordFormGroup(): FormGroup {
    return this.formBuilder.group({
      otp: [
        null,
        [
          Validators.maxLength(4),
          Validators.minLength(4),
          this.validationService.conditionalRequiredValidator(() => this.isFirstPage === false),
        ],
      ],
      username: [
        null,
        [Validators.required, Validators.pattern(this.validationService.emailMobileRegex)],
      ],
    });
  }

  private createResetPasswordFormGroup(): FormGroup {
    return this.formBuilder.group({
      confirm_password: [
        null,
        [
          Validators.required,
          this.passwordMatchValidator(() => this.resetPasswordForm.get(['new_password'])),
          Validators.maxLength(environment.maxCharLimit),
        ],
      ],
      new_password: [
        null,
        [
          Validators.required,
          this.validationService.regexValidator(this.validationService.passwordRegex, {
            [FormErrors.password]: true,
          }),
          Validators.maxLength(environment.maxCharLimit),
        ],
      ],
    });
  }

  private onChangePassword(): void {
    this.resetPasswordForm.get(['confirm_password'])?.updateValueAndValidity({
      emitEvent: false,
    });
  }

  private passwordMatchValidator(passwordGetter: () => any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent == null) {
        return null;
      }

      return passwordGetter().value === control.value
        ? null
        : {
            [FormErrors.passwordMatch]: true,
          };
    };
  }

  private resendOtp(): void {
    const username = this.forgotPasswordForm.get('username')?.value;
    const formData = {
      id: this.selectedUserID,
      username,
    };

    this.authService
      .resendOtp(formData, [422])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          // Success
          this.helperService.showMessage('success', res.message);
        },
        error: (err: CustomError) => {
          // Error
          this.helperService.showMessage('error', err.errors.message[0]);
          this.validationService.addResponseErrorToForm(this.forgotPasswordForm, err);
        },
      });
  }

  private setEventListeners(): void {
    this.resetPasswordForm
      .get(['new_password'])
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(this.onChangePassword.bind(this));
  }

  private startTimer(): void {
    this.resendOtpTimeLeft = 120;
    this.resendOtpInterval = setInterval(() => {
      if (this.resendOtpTimeLeft > 0) {
        this.resendOtpTimeLeft -= 1;
      } else {
        this.stopTimer();
      }
    }, 1000);
  }

  private stopTimer(): void {
    this.resendOtpTimeLeft = 0;
    clearInterval(this.resendOtpInterval);
  }
}
