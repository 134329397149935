import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

import { ValidationService } from '../../../core/services/validation/validation.service';
import { FormErrors } from '../../configs/form-errors.config';
import { ViewSample } from '../../configs/view-sample.config';
import { CustomError } from '../../models/api-response';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnChanges {
  @Output() upload: EventEmitter<File> = new EventEmitter<File>();
  @Output() viewSample: EventEmitter<void> = new EventEmitter<void>();

  @Input() allowedFileTypeList: string[] = ['.csv'];
  @Input() errorFilePath?: string;
  @Input() fileType: string = '.csv';
  @Input() hasViewSample?: boolean = true;
  @Input() label: string = '';
  @Input() uploadErrors?: CustomError;
  @Input() uploadFilePath?: string;
  @Input() uploadType?: ViewSample;

  isFileUploaded: boolean = false;
  selectedFile?: File;
  uploadForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private validationService: ValidationService) {
    this.uploadForm = this.createFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // uploadErrors
    if (changes.uploadErrors != null && !changes.uploadErrors.isFirstChange()) {
      this.validationService.addResponseErrorToForm(this.uploadForm, this.uploadErrors!);
    }

    // uploadFilePath
    if (changes.uploadFilePath != null && !changes.uploadFilePath.isFirstChange()) {
      this.clearUpload();
      this.isFileUploaded = true;
    }

    // uploadType
    if (changes.uploadType != null && !changes.uploadType.isFirstChange()) {
      this.clearUpload();
    }
  }

  onChangeFile(e: any): void {
    this.isFileUploaded = false;

    const selectedFile: File = <File>e.target.files[0];
    if (selectedFile != null) {
      this.selectedFile = selectedFile;
      this.uploadForm.get(['file'])?.setValue(this.selectedFile?.name);
      this.uploadForm.get(['file'])?.markAsDirty();
    } else {
      this.clearUpload();
    }
  }

  onClickClear(): void {
    this.clearUpload();
  }

  onClickUpload(): void {
    this.upload.emit(this.selectedFile);
  }

  onClickViewSample(): void {
    this.viewSample.emit();
  }

  private clearUpload(): void {
    delete this.selectedFile;
    this.isFileUploaded = false;
    this.uploadForm.reset();
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group({
      file: [null, [this.fileTypeValidator(this.allowedFileTypeList)]],
      fileInput: [null],
    });
  }

  private fileTypeValidator(allowedFileTypeList: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const filePath: string = control?.value;
      const fileType = filePath?.slice(filePath.lastIndexOf('.'));

      return allowedFileTypeList.includes(fileType)
        ? null
        : {
            [FormErrors.uploadType]: true,
          };
    };
  }
}
