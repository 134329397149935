import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { FormErrors } from '../../../shared/configs/form-errors.config';
import { CustomError } from '../../../shared/models/api-response';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  readonly atleastOneCharacterRegex = /\S+/;
  readonly centreIdLengthRegex = /^\d{11}$/;
  readonly centreIdRegex = /^(?:[1-9][0-9]{10})$/;
  readonly contactNumberRegex = /^\d{10}$/;
  readonly emailMobileRegex = /^(\d{10}|\w+([/.-]?\w+)*@\w+([/.-]?\w+)*(\.\w{2,3}))$/;
  readonly emailRegex = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;
  readonly numberRegex = /^\d+$/;
  readonly passwordRegex = /.{6}/;
  readonly personNameRegex = /^[a-zA-Z _.'-]*$/;
  readonly pincodeRegex = /^[0-9]\d{5}$/;
  readonly salaryRangeRegex = /^[1-9]+\d*(-[1-9]+\d*)?$/;
  readonly specialCharacterRegex = /^[a-zA-Z0-9 /()_.@:-]*$/;
  readonly specialCharacterTextareaRegex = /^[a-zA-Z0-9\n /()_.@:-]*$/;
  readonly urlRegex = /(?:https?:\/\/|www\.)([a-z0-9-]+\.)+[a-z]{2,4}(\.[a-z]{2,4})*(\/[^ ]+)*/i;

  addResponseErrorToForm(form: FormGroup, error: CustomError): void {
    // Add errors returned from BE to form
    if (error.statusCode === 422) {
      // Unprocessable Entity Error
      Object.entries(error.errors).forEach(([key, value]) => {
        form.get(key)?.setErrors({
          [FormErrors.responseError]: value[0],
        });
        form.get(key)?.markAsDirty();
      });
    }
  }

  conditionalRequiredValidator(condition: () => any): ValidatorFn {
    // Add required based on any other condition
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent == null) {
        return null;
      }

      return condition != null && condition() === true ? Validators.required(control) : null;
    };
  }

  getMessage(errorName: string, errorValue: any, fieldName: string): string {
    const errorMessages: Record<string, string> = {
      [FormErrors.badgePointOrder]: `Invalid badge points order`,
      [FormErrors.centreId]: `${fieldName} should lie between 10000000000 and 99999999999`,
      [FormErrors.contactNumber]: `${fieldName} should contain 10 digits`,
      [FormErrors.matDatepickerMin]: `${fieldName} is invalid`,
      [FormErrors.maxLength]: `Maximum ${errorValue.requiredLength} characters allowed`,
      [FormErrors.max]: `${fieldName} should be upto ${errorValue.max}`,
      [FormErrors.min]: `${fieldName} should be atleast ${errorValue.min}`,
      [FormErrors.minLength]: `Minimum ${errorValue.requiredLength} characters is required`,
      [FormErrors.monthYear]: 'Month Year field should limited to current year and month',
      [FormErrors.onlyAlphabets]: `${fieldName} field is invalid`,
      [FormErrors.password]: ``,
      [FormErrors.passwordMatch]: `Both passwords must match`,
      [FormErrors.pattern]: `${fieldName} format is invalid`,
      [FormErrors.pincode]: `${fieldName} should contain 6 digits`,
      [FormErrors.required]: `${fieldName} is required`,
      [FormErrors.responseError]: `${errorValue}`,
      [FormErrors.uploadType]: `Invalid file type`,
      [FormErrors.totalCountMismatch]: `Total count is invalid`,
    };

    return errorMessages[errorName];
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    // Show custom error for pattern validation
    return (control: AbstractControl): ValidationErrors | null =>
      control.value != null && control.value !== '' && !regex.test(control.value) ? error : null;
  }
}
