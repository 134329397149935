import { Injectable } from '@angular/core';

import { StorageTokens } from '../../../shared/configs/storage-tokens.config';
import { UserDetails } from '../../../shared/models/user-details';
import { StorageService } from '../storage/storage.service';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  get userDetails() {
    return this.userDetails$.value;
  }

  private userDetails$: BehaviorSubject<UserDetails> = new BehaviorSubject<UserDetails>(
    this.storageService.getFromStorage('local', StorageTokens.userDetails),
  );

  constructor(private storageService: StorageService) {}

  hasRoles(roleList: string[]): boolean {
    return this.userDetails?.roles.some((role) => roleList.includes(role.name));
  }

  setUserDetails(data: any): void {
    this.userDetails$.next(data);
  }

  removeUserDetails(): void {
    this.userDetails$.next(undefined as any);
  }
}
