export const Languages = {
  english: {
    code: 'en',
    label: 'English',
  },
  gujarati: {
    code: 'gu',
    label: 'ગુજરાતી',
  },
  hindi: {
    code: 'hi',
    label: 'हिंदी',
  },
  malayalam: {
    code: 'ml',
    label: 'മലയാളം',
  },
  tamil: {
    code: 'ta',
    label: 'தமிழ்',
  },
  telugu: {
    code: 'te',
    label: 'తెలుగు',
  },
};
