<div class="file-container">
  <!-- Close Button -->
  <button
    class="p-button-rounded p-button-danger"
    type="button"
    icon="pi pi-times"
    pButton
    pRipple
    (click)="removeFile.emit()"
  ></button>

  <!-- Image Preview -->
  <div class="preview">
    <img [src]="url" />
  </div>

  <div
    class="details-section"
    [class.compact]="isCompact || mediaObserver.isActive('xs')"
    *ngIf="name != null && size != null"
  >
    <!-- File Name -->
    <div class="file-name">{{ name }}</div>

    <!-- File Size -->
    <div>{{ helperService.formatSize(size) }}</div>
  </div>
</div>
