<!-- Title -->
<h5 mat-dialog-title>
  {{ confirmationData.title ?? 'common.warning' | translate | titlecase }}
</h5>

<!-- Content -->
<mat-dialog-content>
  <p>{{ confirmationData.message ?? 'common.confirmation' | translate }}</p>

  <div
    class="dialog-actions"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="15px"
    fxLayoutAlign="center"
    fxLayoutAlign.lt-md="center center"
  >
    <!-- Confirm Button -->
    <button mat-flat-button color="accent" [mat-dialog-close]="true">
      {{ confirmationData.confirmButtonText ?? 'common.confirm' | translate | titlecase }}
    </button>

    <!-- Cancel -->
    <button class="cancel" mat-flat-button [mat-dialog-close]="false">
      {{ confirmationData.cancelButtonText ?? 'common.cancel' | translate | titlecase }}
    </button>
  </div>
</mat-dialog-content>
