import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MonthPickerDirective } from './month-picker/month-picker.directive';
import { PermissionDirective } from './permission/permission.directive';
import { RoleDirective } from './role/role.directive';

@NgModule({
  declarations: [MonthPickerDirective, PermissionDirective, RoleDirective],
  imports: [CommonModule],
  exports: [MonthPickerDirective, PermissionDirective, RoleDirective],
})
export class DirectivesModule {}
