import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HANDLED_ERRORS } from '../../../../shared/configs/http-context.config';
import { StorageTokens } from '../../../../shared/configs/storage-tokens.config';
import { ApiResponse } from '../../../../shared/models/api-response';
import { StorageKey } from '../../../../shared/models/storage-key.type';
import { UserDetails } from '../../../../shared/models/user-details';
import { PushNotificationService } from '../../push-notification/push-notification.service';
import { StorageService } from '../../storage/storage.service';
import { UserDetailsService } from '../../user-details/user-details.service';
import { ApiService } from '../api.service';

import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ApiService {
  constructor(
    httpClient: HttpClient,
    private pushNotificationService: PushNotificationService,
    private router: Router,
    private storageService: StorageService,
    private userDetailsService: UserDetailsService,
  ) {
    super(httpClient);
  }

  get isLoggedIn(): boolean {
    return !!this.userDetailsService.userDetails;
  }

  get token(): string {
    return this.storageService.getFromStorage('local', StorageTokens.accessToken);
  }

  fetchOtp(formData: any, handledErrors: number[] = []): Observable<ApiResponse<any>> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<ApiResponse<any>>(
      `${this.apiUrl.userService}/public/api/v1/forgot-password/admin`,
      formData,
      {
        context,
      },
    );
  }

  login(data: any): Observable<ApiResponse<UserDetails>> {
    return this.post<ApiResponse<UserDetails>>(
      `${this.apiUrl.userService}/public/api/v1/login/admin`,
      {
        ...data,
        device: 'browser',
      },
    ).pipe(
      tap((res) => {
        this.resetLocalStorage();
        // Saving new values to storage
        this.storageService.addToStorage('local', StorageTokens.accessToken, res.token);
        this.storageService.addToStorage('local', StorageTokens.userDetails, res.data);
        this.storageService.addToStorage('local', StorageTokens.jwtToken, res.jwt_token);
        this.storageService.addToStorage('local', StorageTokens.supersetUrl, res.superset_url);
        this.storageService.addToStorage('local', StorageTokens.freescoutUrl, res.freescout_url);
        this.storageService.addToStorage(
          'local',
          StorageTokens.freescoutHelpUrl,
          res.freescout_help_url,
        );
        this.storageService.addToStorage(
          'local',
          StorageTokens.supersetAppVersion,
          res.superset_app_version,
        );
        this.userDetailsService.setUserDetails(res.data);
      }),
    );
  }

  logout(): Observable<any> {
    return this.post<any>(`${this.apiUrl.userService}/public/api/v1/logout`).pipe(
      tap(() => {
        this.logoutLocally();
        this.router.navigate(['login']);
      }),
    );
  }

  logoutLocally(): void {
    this.pushNotificationService.deleteFcmToken().pipe(take(1)).subscribe();
    this.resetLocalStorage();
    this.userDetailsService.removeUserDetails();
  }

  resendOtp(formData: any, handledErrors: number[] = []): Observable<ApiResponse<any>> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<ApiResponse<any>>(
      `${this.apiUrl.userService}/public/api/v1/resend-otp`,
      formData,
      {
        context,
      },
    );
  }

  resetPassword(formData: any, handledErrors: number[] = []): Observable<ApiResponse<any>> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<ApiResponse<any>>(
      `${this.apiUrl.userService}/public/api/v1/reset-password`,
      formData,
      {
        context,
      },
    );
  }

  verifyOtp(formData: any, handledErrors: number[] = []): Observable<ApiResponse<any>> {
    const context = new HttpContext().set(HANDLED_ERRORS, handledErrors);

    return this.post<ApiResponse<any>>(
      `${this.apiUrl.userService}/public/api/v1/otp-verify`,
      formData,
      {
        context,
      },
    );
  }

  private resetLocalStorage(): void {
    // Saving, clearing & restoring values from storage
    const storageItemsToBeStored: StorageTokens[] = [
      // StorageTokens.language
    ];
    const storageItemsList = this.retrieveValuesFromStorage('local', storageItemsToBeStored);
    this.storageService.clearStorage('local');
    this.restoreValuesToStorage('local', storageItemsList);
  }

  private retrieveValuesFromStorage(storageType: StorageKey, keyList: StorageTokens[]): any[] {
    return keyList.map((key) => ({
      key,
      value: this.storageService.getFromStorage(storageType, key),
    }));
  }

  private restoreValuesToStorage(storageType: StorageKey, storageItemList: any[]): void {
    storageItemList.forEach(({ key, value }) => {
      this.storageService.addToStorage(storageType, key, value);
    });
  }
}
