import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { GoogleAnalyticsService } from './core/services/google-analytics/google-analytics.service';
import { LanguageService } from './core/services/language/language.service';

import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private languageService: LanguageService,
    private router: Router,
  ) {
    this.languageService.setDefaultLanguage();
    this.setGoogleAnalyticsConfig();
  }

  setGoogleAnalyticsConfig(): void {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.googleAnalyticsService.setAnalyticsConfig(event.urlAfterRedirects);
      });
  }
}
