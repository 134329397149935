export const globalEnvironment = {
  fileUploadSizeLimit: 10240000,
  maxCharLimit: 300,
  minCharLimit: 3,
  messages: {
    error: 'Oops! Something went wrong',
    sessionTimeOut: 'Session Expired! Please login again',
  },
  pageOptions: {
    pageSize: 15,
    pageSizeOptions: [15, 30, 60, 90, 120],
    showFirstLastButtons: true,
  },
  snackbar: {
    duration: {
      info: 3000,
      success: 2250,
      error: 6000,
    },
    horizontalPosition: 'end',
    verticalPosition: 'top',
  },
};
