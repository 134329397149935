import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { HelperService } from '../../services/helper/helper.service';
import { AuthService } from '../../services/http/auth/auth.service';
import { PushNotificationService } from '../../services/push-notification/push-notification.service';
import { ValidationService } from '../../services/validation/validation.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
  loginForm: FormGroup;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private pushNotificationService: PushNotificationService,
    private router: Router,
    private validationService: ValidationService,
  ) {
    this.loginForm = this.formBuilder.group({
      password: [null, [Validators.required, Validators.maxLength(environment.maxCharLimit)]],
      username: [
        null,
        [
          Validators.required,
          Validators.pattern(this.validationService.emailRegex),
          Validators.maxLength(environment.maxCharLimit),
        ],
      ],
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickForgotPassword(): void {
    this.router.navigate(['/forgot-password']);
  }

  onSubmit(): void {
    if (this.loginForm.invalid) {
      return;
    }

    const formData = {
      ...this.loginForm.value,
    };

    this.authService
      .login(formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.helperService.showMessage('success', res.message);
        this.getFcmTokenFromFirebase();
        this.navigateToNextPage();
      });
  }

  private getFcmTokenFromFirebase(): void {
    // Get firebase FCM token
    this.pushNotificationService.getFcmTokenFromFirebase().subscribe();
  }

  private navigateToNextPage(): void {
    const { redirectUrl } = this.activatedRoute?.snapshot?.queryParams;
    if (redirectUrl != null) {
      // If redirect url is present
      const urlObj = new URL(window.location.origin + redirectUrl);
      const queryParams: any = {};
      urlObj.searchParams.forEach((value, key) => {
        queryParams[key] = value;
      });
      // Setting additional redirect flag to target url
      queryParams.isRedirect = true;
      this.router.navigate([urlObj.pathname], {
        queryParams,
      });
    } else {
      // If no redirect url is present
      this.router.navigateByUrl('/quest');
    }
  }
}
