import { Injectable } from '@angular/core';

import { StorageKey } from '../../../shared/models/storage-key.type';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  storage: Record<StorageKey, Storage> = {
    local: localStorage,
    session: sessionStorage,
  };

  addToStorage(storageType: StorageKey, key: string, value: any) {
    this.storage[storageType].setItem(key, JSON.stringify(value));
  }

  clearStorage(storageType: StorageKey): void {
    this.storage[storageType].clear();
  }

  deleteFromStorage(storageType: StorageKey, key: string): any {
    this.storage[storageType].removeItem(key);
  }

  getFromStorage(storageType: StorageKey, key: string): any {
    const item = this.storage[storageType].getItem(key);

    if (item == null) {
      return null;
    }

    return JSON.parse(item);
  }
}
