<div class="forgot-password-container" fxLayout="column" fxLayoutAlign="center center">
  <!-- Quest Icon -->
  <div>
    <img src="assets/images/logos/quest-alliance.svg" />
  </div>

  <!-- Welcome -->
  <h1 class="underline">{{ 'login.welcome' | translate }}</h1>

  <!-- Forgot Password Enter Email/Mobile -->
  <div fxLayout="row" fxLayoutAlign="center" fxFlexOffset="40px" fxFlexAlign="stretch">
    <mat-card fxFlex.gt-xs="570px" fxFlex.xs="95" *ngIf="isFirstPage">
      <mat-card-content fxLayout="row" fxLayoutAlign="center">
        <form
          fxFlex.gt-md="60"
          fxFlex.md="70"
          fxFlex.sm="80"
          fxFlex.xs="90"
          fxLayout="column"
          fxLayoutAlign="center stretch"
          [formGroup]="forgotPasswordForm"
        >
          <!-- Heading 1 -->
          <h2 fxLayoutAlign="center" fxLayout.md="center">
            {{ 'forgot_password.reset_password' | translate }}
          </h2>

          <!-- Email ID / Mobile Number -->
          <div class="p-field" fxFlexOffset="50px">
            <span class="p-float-label">
              <input type="text" pInputText formControlName="username" />
              <label>{{ 'forgot_password.email_mobile' | translate }}</label>
            </span>
            <app-validation-error
              [control]="forgotPasswordForm.get(['username'])"
              [field]="'forgot_password.email_mobile' | translate"
            ></app-validation-error>
          </div>
        </form>
      </mat-card-content>

      <!-- Button Row -->
      <div fxLayoutAlign="space-around" class="reset-password">
        <!-- Send OTP -->
        <button
          mat-flat-button
          color="primary"
          [disabled]="forgotPasswordForm.invalid"
          (click)="onClickSendOTP()"
          *ngIf="!isLoading; else spinner"
        >
          {{ 'forgot_password.send_otp' | translate }}
        </button>
        <ng-template #spinner>
          <mat-spinner [diameter]="25" [strokeWidth]="3"></mat-spinner>
        </ng-template>
      </div>
    </mat-card>
  </div>

  <!-- Verify OTP -->
  <div fxLayout="row" fxLayoutAlign="center" fxFlexAlign="stretch">
    <mat-card fxFlex.gt-xs="570px" fxFlex.xs="95" *ngIf="isVerifyOtp">
      <mat-card-content fxLayout="column" fxLayoutAlign="center stretch">
        <form
          fxFlex.gt-md="60"
          fxFlex.md="70"
          fxFlex.sm="80"
          fxFlex.xs="90"
          fxLayout="column"
          fxLayoutAlign="center stretch"
          [formGroup]="forgotPasswordForm"
        >
          <!-- Heading 1 -->
          <h2 fxLayoutAlign="center" fxLayout.md="center">
            {{ 'forgot_password.reset_password' | translate }}
          </h2>

          <!-- Heading 2 -->
          <div fxFlexOffset="30px" fxLayoutAlign="center" fxLayoutAlign.md="center ">
            <h3 class="enter-otp">
              {{
                'forgot_password.enter_otp'
                  | translate: { name: forgotPasswordForm.get(['username'])?.value.slice(0, 4) }
              }}
            </h3>
          </div>

          <!-- OTP -->
          <div fxLayoutAlign="center" fxFlexOffset="30px">
            <div fxFlex="85px">
              <p-inputMask
                placeholder="X-X-X-X"
                formControlName="otp"
                mask="9-9-9-9"
                slotChar="X"
                [unmask]="true"
              ></p-inputMask>
              <app-validation-error
                [control]="forgotPasswordForm.get(['otp'])"
                [field]="'forgot_password.otp' | translate"
              ></app-validation-error>
            </div>
          </div>
        </form>
      </mat-card-content>

      <!-- Button Row -->
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <!-- Resend OTP -->
        <button
          mat-flat-button
          color="accent"
          (click)="onClickResendOtp()"
          *ngIf="resendOtpTimeLeft === 0; else otpTimer"
        >
          {{ 'forgot_password.resend_otp' | translate }}
        </button>

        <!-- Otp Timer -->
        <ng-template #otpTimer>
          <span class="otp-timer">
            {{
              'forgot_password.resend_otp_in'
                | translate: { time: (resendOtpTimeLeft * 1000 | date: 'mm:ss':'UTC') }
            }}
          </span>
        </ng-template>

        <!-- Verify OTP -->
        <button
          mat-flat-button
          color="primary"
          [disabled]="forgotPasswordForm.invalid"
          (click)="onClickVerifyOTP()"
        >
          {{ 'forgot_password.verify_otp' | translate }}
        </button>
      </div>
    </mat-card>
  </div>

  <!-- Reset Password -->
  <div fxLayout="row" fxLayoutAlign="center" fxFlexAlign="stretch">
    <mat-card fxFlex.gt-xs="570px" fxFlex.xs="95" fxFlexAlign="stretch" *ngIf="isResetPassword">
      <mat-card-content fxLayout="row" fxLayoutAlign="center">
        <form
          fxFlex.gt-md="70"
          fxFlex.md="70"
          fxFlex.sm="80"
          fxFlex.xs="90"
          fxLayout="column"
          fxLayoutAlign="center stretch"
          [formGroup]="resetPasswordForm"
        >
          <!-- Reset Password -->
          <h2 fxLayoutAlign="center">{{ 'forgot_password.reset_password' | translate }}</h2>

          <!-- New password -->
          <div class="p-field padding-top">
            <span class="p-float-label required">
              <p-password
                formControlName="new_password"
                styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                [toggleMask]="true"
              >
                <ng-template pTemplate="content">
                  <ul>
                    <li>{{ 'common.password_title' | translate }}</li>
                    <li>{{ 'common.password_criteria_1' | translate }}</li>
                  </ul>
                </ng-template>
              </p-password>

              <label>{{ 'forgot_password.password' | translate }}</label>
            </span>
            <app-validation-error
              [control]="resetPasswordForm.get('new_password')"
              [field]="'forgot_password.password' | translate"
            ></app-validation-error>
          </div>

          <!-- Confirm Password -->
          <div class="p-field">
            <span class="p-float-label required">
              <p-password
                formControlName="confirm_password"
                styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                [toggleMask]="true"
                [feedback]="false"
              ></p-password>

              <label>{{ 'forgot_password.confirm_password' | translate }}</label>
            </span>
            <app-validation-error
              [control]="resetPasswordForm.get('confirm_password')"
              [field]="'forgot_password.confirm_password' | translate"
            ></app-validation-error>
          </div>

          <!-- Button Row -->
          <div fxLayoutAlign="center">
            <!-- Done Button -->
            <button
              mat-flat-button
              color="primary"
              [disabled]="resetPasswordForm.invalid"
              (click)="onClickSavePassword()"
            >
              {{ 'forgot_password.done' | translate }}
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Password Changed -->
  <div fxLayout="row" fxLayoutAlign="center" fxFlexAlign="stretch" *ngIf="isPasswordChanged">
    <div fxLayout="column" fxFlex.xs="95" fxFlexAlign="center">
      <!-- Heading -->
      <div class="password-change" fxLayout="column" fxLayoutAlign="center center">
        {{ 'forgot_password.password_changed' | translate }}
      </div>

      <!-- Success Image -->
      <div fxLayout="column" fxLayoutAlign="center center" fxFlexOffset="40px">
        <img class="image-password-change" src="assets/images/registration_success.svg" />
      </div>
    </div>
  </div>

  <!-- Last Row -->
  <!-- Remember Password -->
  <div
    class="link-text"
    fxFlexOffset="40px"
    routerLink="/login"
    *ngIf="!isPasswordChanged; else successMessage"
  >
    {{ 'forgot_password.remember_password' | translate }}
    <button mat-flat-button color="accent">{{ 'forgot_password.login' | translate }}</button>
  </div>

  <!-- Success Message -->
  <ng-template #successMessage>
    <button mat-flat-button color="accent" fxFlexOffset="40px" routerLink="/login">
      {{ 'forgot_password.login' | translate }}
    </button>
  </ng-template>
</div>
