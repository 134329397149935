<div class="core-container" fxLayout="column" fxLayoutAlign="center center">
  <!-- Language Selection -->
  <div class="language-select">
    <!-- <app-language-select></app-language-select> -->
  </div>

  <div class="spacer" fxHide fxShow.xs></div>

  <!-- Quest Icon -->
  <div>
    <img src="assets/images/logos/quest-alliance.svg" />
  </div>

  <!-- Welcome -->
  <h1 class="core-main-title">{{ 'login.welcome' | translate }}</h1>

  <!-- Login -->
  <h2 class="core-sub-title underline">Login</h2>

  <!-- Login Card -->
  <div fxFlexAlign="stretch" fxFlexOffset="15px" fxLayout="row" fxLayoutAlign="center">
    <mat-card fxFlex.gt-xs="570px" fxFlex.xs="95">
      <mat-card-content fxLayout="row" fxLayoutAlign="center">
        <form
          fxFlex.gt-md="60"
          fxFlex.md="70"
          fxFlex.sm="80"
          fxFlex.xs="90"
          fxLayout="column"
          fxLayoutAlign="center stretch"
          fxLayoutGap="5px"
          [formGroup]="loginForm"
          (ngSubmit)="onSubmit()"
        >
          <!-- Email -->
          <div class="p-field">
            <span class="p-float-label">
              <input type="text" pInputText formControlName="username" />
              <label>{{ 'login.email' | translate }}</label>
            </span>
            <app-validation-error
              [control]="loginForm.get(['username'])"
              [field]="'login.email' | translate"
            ></app-validation-error>
          </div>

          <!-- Password -->
          <div class="p-field">
            <span class="p-float-label">
              <p-password
                formControlName="password"
                appendTo="body"
                styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                [toggleMask]="true"
                [feedback]="false"
              >
              </p-password>
              <label>{{ 'login.password' | translate }}</label>
            </span>
            <app-validation-error
              [control]="loginForm.get(['password'])"
              [field]="'login.password' | translate"
            ></app-validation-error>
          </div>

          <!-- Login Button -->
          <button
            mat-flat-button
            fxFlexAlign="center"
            color="primary"
            [disabled]="loginForm.invalid"
          >
            {{ 'login.login' | translate }}
          </button>

          <!-- Forgot Password? -->
          <div class="forgot-password" fxLayoutAlign="center" (click)="onClickForgotPassword()">
            Forgot Password?
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
