<!-- File Upload -->
<div fxLayout="column">
  <!-- View Sample -->
  <div class="label">
    {{ label | translate
    }}<span *ngIf="hasViewSample" (click)="onClickViewSample()">{{
      'file_upload.view_sample' | translate
    }}</span>
  </div>

  <form
    fxFlexOffset="10px"
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    [formGroup]="uploadForm"
  >
    <!-- Hidden File Upload Input -->
    <input
      type="file"
      #fileUpload
      [accept]="allowedFileTypeList.join(',')"
      formControlName="fileInput"
      (change)="onChangeFile($event)"
    />

    <!-- File Upload Input -->
    <div fxLayout="column">
      <div class="p-inputgroup">
        <!-- File Name -->
        <input
          formControlName="file"
          type="text"
          pInputText
          placeholder="Browse your files"
          [readonly]="true"
          (click)="fileUpload.click()"
        />

        <!-- Select Button -->
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-plus"
          (click)="fileUpload.click()"
          *ngIf="!selectedFile"
        ></button>

        <!-- Clear Button -->
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-times"
          (click)="onClickClear()"
          *ngIf="selectedFile"
        ></button>
      </div>
    </div>

    <!-- Upload Button -->
    <button
      mat-flat-button
      color="primary"
      [disabled]="uploadForm.invalid"
      (click)="onClickUpload()"
    >
      {{ 'file_upload.upload' | translate }}
    </button>
  </form>

  <!-- validation msg -->
  <app-validation-error
    [control]="uploadForm.get(['file'])"
    [field]="'file_upload.file' | translate"
  ></app-validation-error>

  <!-- Upload Report Links -->
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    *ngIf="isFileUploaded"
  >
    <!-- Download Uploaded File -->
    <a [href]="uploadFilePath" *ngIf="uploadFilePath">
      {{ 'file_upload.download_uploaded_file' | translate }}
    </a>

    <!-- Download Error File -->
    <a [href]="errorFilePath" *ngIf="errorFilePath">
      {{ 'file_upload.download_error_report' | translate }}
    </a>
  </div>
</div>
