// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { globalEnvironment } from './environment.global';

//const apiBaseUrl = 'http://localhost/quest';
const apiBaseUrl = 'https://api.questapp.flexsin.org'

export const environment = {
  production: false,
  ...globalEnvironment,
  apiUrl: {
    adminService: `${apiBaseUrl}/admin-service`,
    commonService: `${apiBaseUrl}/common-service`,
    communityService: `${apiBaseUrl}/community-service`,
    jobService: `${apiBaseUrl}/job-service`,
    notificationService: `${apiBaseUrl}/notification-service`,
    userService: `${apiBaseUrl}/user-service`,
  },
  firebase: {
    // Also present in firebase-messaging-sw.js
    apiKey: 'AIzaSyCeBI-7aGsUVwuzsP9AbdaSitFY8rvA5fo', // Auth / General Use
    authDomain: 'my-quest-65f06.firebaseapp.com', // Auth with popup/redirect
    projectId: 'my-quest-65f06', // General Use
    storageBucket: 'my-quest-65f06.appspot.com', // Storage
    messagingSenderId: '1002327304037', // Cloud Messaging (Also present in manifest.json)
    appId: '1:1002327304037:web:f4428bf2f2c319cfaea38c', // General Use
    measurementId: 'G-XPX13ZQKWZ',
  },
  gaTag: 'G-1XR219ZDEN',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
