import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translateService: TranslateService) {
    super();

    this.translateService.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    const of = this.translateService.instant('paginator.of');

    if (length === 0 || pageSize === 0) {
      return `0 ${of} ${length}`;
    }

    const newLength = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < newLength ? Math.min(startIndex + pageSize, newLength) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${of} ${newLength}`;
  };

  translateLabels() {
    this.translateService
      .get([
        'paginator.items_per_page',
        'paginator.next_page',
        'paginator.previous_page',
        'paginator.first_page',
        'paginator.last_page',
      ])
      .subscribe((translation) => {
        this.itemsPerPageLabel = translation['paginator.items_per_page'];
        this.nextPageLabel = translation['paginator.next_page'];
        this.previousPageLabel = translation['paginator.previous_page'];
        this.firstPageLabel = translation['paginator.first_page'];
        this.lastPageLabel = translation['paginator.last_page'];
        this.changes.next();
      });
  }
}
