import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { FileUploadPreviewComponent } from './components/file-upload-preview/file-upload-preview.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LanguageSelectComponent } from './components/language-select/language-select.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';
import { DirectivesModule } from './directives/directives.module';
import { MaterialModule } from './material.module';
import { PipesModule } from './pipes/pipes.module';
import { PrimeNgModule } from './prime-ng.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    FileUploadComponent,
    FileUploadPreviewComponent,
    LanguageSelectComponent,
    LoaderComponent,
    ValidationErrorComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    FlexLayoutModule,
    FormsModule,
    InfiniteScrollModule,
    MaterialModule,
    PipesModule,
    PrimeNgModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      extend: true,
    }),
  ],
  exports: [
    CommonModule,
    ConfirmationDialogComponent,
    DirectivesModule,
    FileUploadComponent,
    FileUploadPreviewComponent,
    FlexLayoutModule,
    FormsModule,
    InfiniteScrollModule,
    LanguageSelectComponent,
    LoaderComponent,
    MaterialModule,
    PipesModule,
    PrimeNgModule,
    ReactiveFormsModule,
    TranslateModule,
    ValidationErrorComponent,
  ],
})
export class SharedModule {}
