import {
  ErrorStateMatcher,
  MAT_DATE_LOCALE,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

import { environment } from '../../../environments/environment';
import { CustomMatPaginatorIntl } from '../utils/custom-mat-paginator-intl';

export const materialProviders = [
  {
    provide: ErrorStateMatcher,
    useClass: ShowOnDirtyErrorStateMatcher,
  },
  {
    provide: MAT_DATE_LOCALE,
    useValue: 'en-IN',
  },
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: {
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      hasBackdrop: true,
      maxWidth: '95vw',
      restoreFocus: false,
    },
  },
  {
    provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
    useValue: environment.pageOptions,
  },
  {
    provide: MatPaginatorIntl,
    useClass: CustomMatPaginatorIntl,
  },
  {
    provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
    useValue: {
      disableToggleValue: true,
    },
  },
  {
    provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    useValue: {
      position: 'above',
    },
  },
];
