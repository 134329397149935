import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/http/auth/auth.service';
import { LanguageService } from '../../services/language/language.service';

import { Observable } from 'rxjs';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private languageService: LanguageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isApiUrl = Object.values(environment.apiUrl).some((apiUrl) =>
      request.url.startsWith(apiUrl),
    );

    return next.handle(isApiUrl ? this.addHeaders(request) : request);
  }

  addHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    let headers: any = {
      'X-localization': this.languageService.getLanguage(),
    };

    if (this.authService.isLoggedIn) {
      // Adding Auth Token
      headers = {
        ...headers,
        Authorization: `Bearer ${this.authService.token}`,
      };
    }

    return request.clone({
      setHeaders: headers,
    });
  }
}
