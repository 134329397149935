import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { PermissionService } from '../../../core/services/permission/permission.service';
import { Permissions } from '../../configs/permissions.config';

@Directive({
  selector: '[appPermission]',
})
export class PermissionDirective implements OnInit {
  private requiredPermissionList!: Permissions[];

  @Input() set appPermission(permissionList: Permissions[]) {
    this.requiredPermissionList = permissionList;
  }

  constructor(
    private permissionService: PermissionService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    if (this.permissionService.isUserAllowed(this.requiredPermissionList)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
