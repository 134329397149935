import { Injectable } from '@angular/core';

import { Permissions } from '../../../shared/configs/permissions.config';
import { UserDetailsService } from '../user-details/user-details.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private userDetailsService: UserDetailsService) {}

  isActionColumnRequired(requiredPermissionList: Permissions[]): boolean {
    return this.isUserAllowed(requiredPermissionList);
  }

  isToggleDisabled(requiredPermissionList: Permissions[]): boolean {
    return !this.isUserAllowed(requiredPermissionList);
  }

  isUserAllowed(requiredPermissionList: Permissions[]): boolean {
    // Check if user has a role super admin
    const isSuperAdmin = this.userDetailsService.userDetails.roles.some(
      (userRole) => userRole.name === 'super-admin',
    );

    // Check if user has permission if not a super admin
    return isSuperAdmin || requiredPermissionList == null
      ? true
      : this.userDetailsService.userDetails.permissions.some((userPermission) =>
          requiredPermissionList.includes(userPermission.name),
        );
  }
}
